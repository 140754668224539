<template>
  <div>
    <div class="pa-3">
      <div class="overflow-y-auto overflow-x-hidden">
        <dialog-confirm
          :open="openDialog"
          @close="closeDialog"
        ></dialog-confirm>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <div class="d-flex flex-column">
              <h3 class="justify-center">Nuevo Visitante</h3>
              <p>
                La información que ingreses en este espacio del visitante se le
                adjuntará la existente, una vez sincronices con la nube.
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card elevation="6" class="py-4 rounded-lg" color="#F8F8F8">
              <div class="row py-4 px-4">
                <div class="col-sm-12 py-0">
                  <h3 class="my-0">Información QR</h3>
                </div>
                <div class="col-sm-12 d-flex d-inline py-0">
                  <label class="mr-2 custom-font">Nombre: </label>
                  <label class="custom-font" v-text="visitor.fullName"></label>
                </div>
                <div class="col-sm-12 d-flex d-inline py-0">
                  <label class="mr-2 custom-font">Empresa: </label>
                  <label
                    class="custom-font"
                    v-text="visitor.companyName"
                  ></label>
                </div>
                <div class="col-sm-12 d-flex d-inline py-0">
                  <label class="mr-2 custom-font">Cargo: </label>
                  <label
                    class="custom-font"
                    v-text="visitor.companyPosition"
                  ></label>
                </div>
                <div class="col-sm-12 d-flex d-inline py-0">
                  <label class="mr-2 custom-font">Celular: </label>
                  <label class="custom-font" v-text="visitor.phone"></label>
                </div>
                <div class="col-sm-12 d-flex d-inline py-0">
                  <label class="mr-2 custom-font">Correo: </label>
                  <label class="custom-font" v-text="visitor.email"></label>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card class="py-4 px-4 rounded-lg" color="#F8F8F8" elevation="6">
              <div class="row justify-center">
                <div class="col-auto px-4">
                  <v-img
                    :src="imageUrl"
                    ref="imgPhoto"
                    max-height="115"
                    contain
                  />
                </div>
                <div class="col-sm-12 px-4">
                  <input
                    type="file"
                    ref="photo"
                    accept="image/*"
                    capture="camera"
                    class="d-none"
                    @change="setPhoto"
                  />
                  <v-btn
                    outlined
                    class="w-100 black--text border-1"
                    shaped
                    @click="$refs.photo.click()"
                    >Tomar y agregar foto</v-btn
                  >
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card class="px-4 py-4 rounded-lg" color="#F8F8F8" elevation="6">
              <div class="row">
                <div class="col-sm-12 py-0 px-4">
                  <h3 class="font-weight-bold my-0">Notas adicionales</h3>
                </div>
                <div class="col-sm-12 py-1 px-4">
                  <v-textarea
                    :maxlength="maxCharacteresDescription"
                    v-model="visitor.description"
                    class="w-100 custom-font"
                    placeholder="Agrega aquí tus comentarios."
                    rows="3"
                  ></v-textarea>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-btn
              class="w-100 black--text rounded-lg"
              elevation="2"
              @click="save"
              color="secondary"
              >AGREGAR VISITANTE</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-100 {
  min-width: 100% !important;
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.border-1 {
  border-style: solid !important;
  border-color: black !important;
  border-radius: 10px !important;
}

.custom-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #808080;
}
</style>

<script>
import DialogConfirm from "./components/DialogConfirm";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";
import Compressor from "compressorjs";
import eventHub from "@/eventhub";

export default {
  name: "CreateVisitor",
  components: {
    DialogConfirm,
  },
  data() {
    return {
      maxCharacteresDescription: 255,
      visitor: {
        attendeeUuid: null,
        fullName: null,
        companyPosition: null,
        phone: null,
        description: null,
        imageUrl: null,
        email: null,
      },
      openDialog: false,
      imageInBase64: null,
      defaultImage: require("@/assets/images/visitors/visitor-default.png"),
    };
  },
  methods: {
    closeDialog(value) {
      this.openDialog = value;
    },
    isValid() {
      return (
        !this.visitor.attendeeId &&
        !this.visitor.fullName &&
        !this.visitor.companyName
      );
    },
    setPhoto(e) {
      const [file] = e.target.files;
      if (file) {
        new Compressor(file, {
          quality: 0.6,
          success: (result) => {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = () => {
              this.imageInBase64 = reader.result;
            };
          },
          error: (err) => {
            console.info(err);
          },
        });
      }
    },
    cleanData() {
      for (const key in this.visitor) {
        this.visitor[key] = null;
      }
      this.imageInBase64 = null;
    },
    getData() {
      return {
        ...this.visitor,
        imageInBase64: this.imageInBase64,
      };
    },
    async save() {
      this.showLoader();
      let response = await this.$store.dispatch(
        "visitors/saveVisitor",
        this.getData()
      );
      this.hideLoader();
      if (response?.status) {
        if (response.isOffline) {
          this.showSuccess(
            "Visitante creado, recuerda periodicamente sincronizar tus visitantes"
          );
        } else {
          this.showSuccess("Visitante creado");
        }
        this.$router.push({ name: "visitors" });
      } else {
        this.showError("Error al crear visitante");
      }
    },
  },
  computed: {
    temp() {
      return this.$store.getters["visitors/tempVisitor"] ?? {};
    },
    imageUrl() {
      if (this.imageInBase64) {
        return this.imageInBase64;
      } else {
        return this.visitor.imageUrl ?? this.defaultImage;
      }
    },
  },
  mixins: [loaderMixin, notificationMixin],
  mounted() {
    this.cleanData();

    if (!this.temp?.attendeeUuid) this.$router.push({ name: "visitors" });

    this.visitor.attendeeUuid = this.temp.attendeeUuid;
    this.visitor.companyName = this.temp.companyName;
    this.visitor.fullName = this.temp.fullName;
    this.visitor.companyPosition = this.temp.companyPosition;
    this.visitor.phone = this.temp.phone;
    this.visitor.description = this.temp.description;
    this.visitor.imageUrl = this.temp.imageUrl;
    this.visitor.email = this.temp.email;
    this.imageInBase64 = this.temp.imageInBase64;
    this.visitor.index = this.temp.index;
  },
  created() {
    eventHub.$on("showDialogVisitor", () => {
      this.openDialog = true;
    });
  },
};
</script>
